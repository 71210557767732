import React from 'react'
import googleplay from "../../images/google-play-badge.png";
import thankYou from "../../images/ThankYou.jpeg";
export default function ThankYou() {
  return (
    <div className='container'>
        <div className='row'>
        <div className='col-md-6 offset-md-3 text-center'>
            <img src={thankYou} alt='thank you'width={100}/> <br/><br/>
            <p>Registering confirmed! Thanks for Registering with us </p>
            <span className='me-2'>Download our app</span> <img width={150} src={googleplay} alt="google play"/>
        </div>
        </div>
    </div>
  )
}
