import React, {useEffect, useState} from "react";
import '../../css/course-details.css';
import { Row,Col, Button } from 'react-bootstrap';
import VideoIcon from '../../images/noun_video.svg';
import Rupee from '../../images/rupee.svg';
import calender from '../../images/noun-calendar-4692017.svg'
import clock from '../../images/noun-clock-4685145.svg'
import moment from "moment";

const CourseDetails = (props) => {

    const [offerPrize, setOfferPrize]=useState(0);
    useEffect(()=>{
        if(props.data.fees == 0)
        {
            setOfferPrize(0);
        }
        if(props.data.fees > 0 && props.data.discount>0)
        {
            setOfferPrize(Math.round(props.data.fees-((props.data.fees/100)*props.data.discount)));
        }
        if(props.data.discount == 0){
            setOfferPrize(props.data.fees);
        }
    },[]);
    return(
        <div className="course-details-container container container-margin-bottom px-3" >
            <div className="course-title">
            {props.data.title}     
            </div>
            <div className="Course-short-details  mb-4"> 
            {props.data.summary}
            </div>
            <Row className="mt-2 mb-2 ">
                <Col xxl={6} lg={6} xl={6} md={6}  sm={12} className="d-flex justify-content-center video-margin-bottom ">
                    { props.data.show_video ?                    
                    <div className="video-image-container">
                        <div className="video-image">
                        <video className="video-size"  controls>
                            <source src={props.data.video} type="video/mp4"/>                        
                            Your browser does not support HTML video.
                        </video> 
                        </div>
                    </div> 
                    :
                    <>
                    <div className="image-container desktop-view" style={{backgroundImage:`url(${props.data.thumbnail})`}}>                        
                            {/* <img height="100%" src={props.data.thumbnail}/>  */}
                    </div>
                                         
                             <img width="100%" className="image-container mobile-view" src={props.data.thumbnail}/>                      
                    </>
                    }
                </Col>
                
                <Col xxl={6} lg={6} xl={6} md={6}  sm={12} >
                    <div className="book-course">
                        <div className="batch-details desktop-view">
                            <div className="session-info">
                                <div className="">
                                    <div className="mb-3 font-24">
                                        <img src={VideoIcon}  />
                                        <span className="p-3"><span className="font-family-Helvetica">{props.data.session_count}</span> Live session</span>
                                    </div>
                                    <div className="mb-3 font-20 mt-4 ">
                                    <img src={calender} className="pe-4" />
                                     <span className="">Start Date <span className="font-family-Helvetica">{props.selectedCourseDetails.startDate}</span></span>
                                    </div>
                                </div>
                                <div className="border-right m-3"></div>
                                <div className="font-20">
                                    <div style={{height:"60px"}}>
                                        <img src={Rupee} /> <span className="p-3">Price <span className="discount-value" style={{marginLeft:"50px "}}>{offerPrize==0 ? "Free":"₹"+offerPrize }</span></span>
                                        { (props.data.fees>0 && props.data.discount>0)&&
                                            <div className=" original-value" style={{margin:"-28px 0px 0px 87px"}}>₹{props.data.fees}</div>
                                        }
                                    </div>
                                    <div>
                                    <img src={clock} className="pe-2" /><span className="">Time <span className="font-family-Helvetica ">{props.selectedCourseDetails.time}</span></span>
                                    </div>
                                </div>
                            </div>

                            <div className="batch-details-heading font-20">Batch Details</div>

                            <div className="date-box-container">
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(0) > -1 ? "active_day_box" : ""}`}>S</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(1) > -1 ? "active_day_box" : ""}`}>M</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(2) > -1 ? "active_day_box" : ""}`}>T</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(3) > -1 ? "active_day_box" : ""}`}>W</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(4) > -1 ? "active_day_box" : ""}`}>Th</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(5) > -1 ? "active_day_box" : ""}`}>F</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(6) > -1 ? "active_day_box" : ""}`}>S</div>
                            </div>
                        </div>
                        <div className="batch-details mobile-view">
                            <div className="session-info">
                                 <div className="mb-2 font-20">
                                        <img src={VideoIcon} className="pe-2" />
                                        <span className=""><span className="font-family-Helvetica">{props.data.session_count}</span> Live session</span>
                                    </div>
                                    <div className="mb-2 font-20 ">
                                    <img src={calender} className="pe-3" /><span >Start Date <span className="font-family-Helvetica">{props.selectedCourseDetails.startDate}</span></span>
                                    </div>
                                    <div className="font-20 mb-2 ">
                                    <img src={clock} className="pe-3" /><span className="pe-3">Time </span><span className="font-family-Helvetica ">{props.selectedCourseDetails.time}</span>
                                    </div>
                                    <div className="font-20">
                                        <img src={Rupee} className="pe-4"/>

                                        <span className="pe-4">Price</span>

                                        {(props.data.fees>0 && props.data.discount>0)&&
                                            <span className="original-value">₹{props.data.fees} <span className="discount-value">{offerPrize==0 ? "Free":"₹"+offerPrize }</span></span>
                                        }
                                    </div>
                                   
                            </div>
                           

                            <div className="batch-details-heading font-20">Batch Details</div>

                            <div className="date-box-container">
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(0) > -1 ? "active_day_box" : ""}`}>S</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(1) > -1 ? "active_day_box" : ""}`}>M</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(2) > -1 ? "active_day_box" : ""}`}>T</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(3) > -1 ? "active_day_box" : ""}`}>W</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(4) > -1 ? "active_day_box" : ""}`}>Th</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(5) > -1 ? "active_day_box" : ""}`}>F</div>
                                <div className={`day-box ${props.courseFrequency && props.courseFrequency.length > 0 && props.courseFrequency.indexOf(6) > -1 ? "active_day_box" : ""}`}>S</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">                            
                            {/* {(!props?.seatsAvailability || (new Date(props.batchStartDate) < new Date())) &&
                                <button className="book-course-btn font-family-Helvetica  disable" >Book Course</button>
                            } */}
                            {props.seatsAvailability && (moment(props.batchStartDate) > moment()) ?
                                <button className="book-course-btn font-family-Helvetica" onClick={props.signInModel}>Book Course</button>
                                :
                                <button className="book-course-btn font-family-Helvetica  disable" >Book Course</button>
                            }
                        </div>
                    </div>    
                </Col>
            </Row>
        </div>
    );
}

export default CourseDetails;