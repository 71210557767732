import React from 'react'
import ReactStars from "react-rating-stars-component";
import { Row, Col, Button } from 'react-bootstrap';
import FullStar from '../images/fullstar.svg';
export default function CourseItem(props) {

    const RedirectToNewCourse = (id) => {
        console.log(window.location.origin + "?course_info=" + id);
        window.location.href = window.location.origin + "/course?course_info=" + id;
    }

    return (
        <div className="course-item-wrapper" >
            <div >
                <img className="course-item-image" src={props.data.thumbnail} alt="icon"></img>
            </div>
            <div className="course-item-title" onClick={() => RedirectToNewCourse(props.data.id)} style={{ margin: "10px 0",fontSize: "18px"}}>
                <span>{props.data.title}</span>
            </div>
            <div className="course-item-desc">
                <span>{props.data.description.slice(0, 100)}...</span>
            </div>
            <div style={{ margin: "10px 0" }}>
                <div className='d-flex justify-content-between  align-items-center'>
                    <div className="course-item-starts">
                        <span style={{fontSize:"15px"}}>Rating - {props.data.avg_rating > 0 ? Math.round(props.data.avg_rating) : 0}/{5} stars</span>
                    </div>
                    <div className="course-starts">
                        <ReactStars
                            count={5}
                            size={25}
                            isHalf={true}
                            value={Math.round(props.data.avg_rating)}
                            fullIcon={<img src={FullStar} />}
                            activeColor="#FFC70D"
                            edit={false}
                        />
                    </div>
                    </div>
            </div>
            <div style={{ margin: "10px 0", textAlign: "center" }}>
                <Button className="btn btn-register" onClick={() => RedirectToNewCourse(props.data.id)}>{props.btntitle}</Button>
            </div>
        </div>
    )
}
