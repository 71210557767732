import Header from './Header';
import axios from 'axios';
import React, {useEffect, useState} from "react";
import Footer from '../Footer/Footer';
import { Row,Col, Button } from 'react-bootstrap';
import CourseItem from '../../shared/course-item'
const Home = ()=>{

    const [availableCourses,setAvailableCourses] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{
        getCourseDetails();
    },[]);

    const getCourseDetails = () => {
        axios.get("/page/all/").then(res => {
          //setLoading(false);
          if (res.data.data.result.course_info) {
    
            setAvailableCourses(res.data.data.result.course_info);
            setLoading(false);
          }
        }).catch(error => {
          console.log(error);
        }
        );
      }
    
    return (
        <>
         <div className='main-dashboard'>
            <Header />
            {isLoading && <div className="loader-div"> <div className="loader"></div></div>}
              {
              !isLoading && 
              <>
                  <div className='container custom-container'>
                      <Row >{
                        availableCourses.map((item, index) => {
                              return <Col key={index} xxl={4} lg={4} xl={4} md={6}  sm={12} className="my-3">                     
                                      <CourseItem key={index} data={item} btntitle="Register Now" />                        
                              </Col>  
                              }) 
                          }      
                      </Row>
                  </div>
                  <Footer/>
                </> 
              }
            
        </div>
        </>
    );
}


export default Home;