import React, { useState } from 'react'

export default function Syllabus(props) {
    
    const [showMore, setShowMore]= useState(true); 
    
    return (
        <>
            <div className="course-Label ">
             <span>   Syllabus </span> <span onClick={() =>setShowMore(!showMore)} className="show-more">See {showMore ?"less":"more"}</span>
            </div>
            { showMore && <>
                <div className='container-margin-bottom'>
                <div className="about-course-description" dangerouslySetInnerHTML={{ __html: props.data }}></div>
            </div>
            </>
            }
        </>
    )
}
