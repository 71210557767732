import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import shareIcon from '../../images/shareIcon.svg';
import '../../css/about-course.css';
import ClockMini from '../../images/clockMini.svg';
import CertificateIcon from '../../images/certificateIcon.svg';

import leval1 from '../../images/Level_beginer.svg'
import leval2 from '../../images/Level_intermidiate.svg'
import leval3 from '../../images/Level_advanced.svg'
import mic from '../../images/mic.svg';
import playMini from '../../images/playMini.svg';
import Syllabus from "../Syllabus/syllabus";
import { ToastContainer, toast } from 'react-toastify';
const AboutCourse = (props) => {

    const shareLink=(link)=>{
        //window.location=link;
        window.open(link, '_blank');
    }

    const shareLinkMobile = (link) => {
        link = "Checkout this amazing course from coachx ".concat(link);
        window.open("whatsapp://send?text=".concat(link),'_blank')
        // console.log("whatsapp://send?text=".concat(link));
        //<a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share">Share via Whatsapp</a>
       // window.open(link, '_blank');
        // navigator.clipboard.writeText(link);
        // toast("Copied");
    }
    
    const [courseLevel, setCourseLevel]= useState(""); 
    const [levelLmg, setLavelImage] =useState("");
    
    
    useEffect(()=>{
        if(props.data.level==='B'){
            setCourseLevel("Beginner Level");
        }
            
            if(props.data.level==='E'){
                setCourseLevel("Expert Level");
               
            }
              
                if(props.data.level==='I'){
                    setCourseLevel("Intermediate Level");
                    
                }
    },[props.data.level]);
    return (
        <>
        <ToastContainer 
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        />
            <div className="share-course-container container-margin-bottom">
                <Row className="m-0">
                    <Col xl={9} md={9} sm={12} className="share-course-text font-family-Helvetica">Learn together with your friends</Col>
                    <Col xl={3} md={3} sm={12} className="invite-btn-container">
                        <div className="invite-btn desktop-view"  onClick={() =>shareLink("https://api.whatsapp.com/send?text="+props.data.share_link)}><img src={shareIcon} className="pe-3"></img>Invite</div>
                        <div className="invite-btn mobile-view"  onClick={() =>shareLinkMobile(props.data.share_link)}><img src={shareIcon} className="pe-3"></img>Invite</div>
                    </Col>
                </Row>
            </div>
            <div className="about-container container-margin-bottom">
                <div className="sectionTitle">
                    ABOUT COURSE
                </div>
                <div className="titleUnderline">
                    <div className="thickLine about-underline"></div>
                    <div className="thinLine"></div>
                </div>

                <Row className="m-0 mb-48">
                    <Col md={3} sm={12} className="about-sub-container">
                        {props.data.level==='B' && <img className="about-icon" src={leval1}/>}
                        {props.data.level==='E' && <img className="about-icon" src={leval3}/>}
                        {props.data.level==='I' && <img className="about-icon" src={leval2}/>}
                        <span className="about-label">{courseLevel}</span>
                    </Col>
                    <Col md={3} sm={12} className="about-sub-container">
                        <img className="about-icon" src={playMini}></img>
                        <span className="about-label">{props.data.session_count} Live Sessions</span>
                    </Col>
                    <Col md={3} sm={12} className="about-sub-container">
                        <img className="about-icon" src={ClockMini}></img>
                        <span className="about-label">{props.data.session_info.duration} mins each</span>
                    </Col>
                    <Col md={3} sm={12} className="about-sub-container">
                        <img className="about-icon" src={mic}></img>
                        <span className="about-label">{props.data.language}</span>
                    </Col>
                    <Col md={5} sm={12} className="about-sub-container">
                        <img className="about-icon" src={CertificateIcon}></img>
                        <span className="about-label">Certificate of Completion</span>
                    </Col>
                </Row>

                <div className="course-Label font-family-Helvetica">
                    {props.data.title}
                </div>
                <div className="about-course-description">
                    {props.data.description}
                </div>
            </div>
            <div>
                <Syllabus data={props.data.syllabus} ></Syllabus>
            </div>
        </>
    );
}

export default AboutCourse;

