import './App.css';
import axios from "axios";
import RouterComp from "./components/layout/RouterComp";
import {ThemeProvider} from 'react-bootstrap';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import DownloadApp from './shared/DownloadApp';
import ThankYou from './components/thankYou/ThankYou';


function App() {

  let deviceUDID = new Date();
  if(!localStorage.getItem("DEVICE-UDID")){
    localStorage.setItem("DEVICE-UDID", deviceUDID);
  }
  deviceUDID = localStorage.getItem("DEVICE-UDID");
  if(!localStorage.getItem("SessionToken"))
  {
    localStorage.setItem("SessionToken"," ");
  }
//axios.defaults.baseURL = "https://api.coachx.live/";
axios.defaults.baseURL = "https://apistage.coachx.live/";
//axios.defaults.baseURL = "https://apidev.coachx.live:8050/";
axios.defaults.headers.common = {  
  'DEVICE-UDID': deviceUDID
}

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBlS7XaDYDVS0yKHmlRh9Ax2TcPx84GpLE",
  authDomain: "coachx-ae011.firebaseapp.com",
  projectId: "coachx-ae011",
  storageBucket: "coachx-ae011.appspot.com",
  messagingSenderId: "626779934380",
  appId: "1:626779934380:web:f12c605746d6da5da55909",
  measurementId: "G-NSYECX1PT6"
  };

  if(axios.defaults.baseURL =="https://api.coachx.live/" ){
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  }

  return (
    <ThemeProvider
  breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
>
    <RouterComp/>
    {/* <DownloadApp/> */}
    

    </ThemeProvider>
  );
}

export default App;
